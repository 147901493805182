<template>
  <div class="absolute left-0 right-0 top-0">
    <aside
      id="sidebar"
      aria-label="Sidebar"
      class="absolute inset-0 z-10 h-full space-y-2 overflow-y-auto overflow-x-hidden scroll-smooth rounded-lg bg-gray-800 py-4 md:rounded-none"
    >
      <slot name="header" />
      <slot />
    </aside>
  </div>
</template>
